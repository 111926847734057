export default {
    facility: "Facility Sports",
    order: "Order",
    home: "Home",
    countryName: "Hongkong",
    venus: "Major Venus",
    balloting: "Balloting",
    book: "Book",
    selectDate: "Select Date",
    selectYourFriend: "Select Your Friend",
    age: "Age",
    location: "Location",
    sex: "Sex",
    selectCourt: "Public Sports Ground",
    selectCourt_price: "$120hour",
    roomA: "Room A",
    roomB: "Room B",
    roomC: "Room C",
    available: "available",
    select: "Selected",
    unavailable: "unavailable",
    unavailable1: "Unavailable",
    lessThan7days: "Selected is not available now, please book court within {cchfcfs} days.",
    peakHour: "Peak hours",
    selectCourt_tips:
        "The air conditioner is being repaired inside the venue,sorry for the inconvenience",
    tapYourOctopus: "Tap your Octopus",
    scanQRcode: "Scan your Alipay QRcode",
    presentQRcode: "Present your payment QR code",
    invalidQRcodeTips: "QR code is invalid or has not reached the appropriate check-in time.",
    tryAgain: "Try Again",
    tapYourCreidtCard: "Tap your Credit Card",
    paymentFailed: "Payment failed",
    bookingDetail_addPartner: "Add Partner",
    bookingDetail_addPartner_btn: "Add a partner",
    bookingDetail_addEquipment: "Add Equipment",
    bookingDetail_sRate_txt: "Standard rate",
    bookingDetail_cRate_txt: "Equipment rate",
    bookingDetail_equipment_txt: "Equipment",
    bookingDetail_coupon_txt: "Coupon",
    bookingDetail_subtotal_txt: "Subtotal",
    bookingDetail_details_txt: "Details",
    programmeDetail_Introduction: "Introduction",
    bookingDetail_tips1: 'At least ',
    partnerLimit: "At least {num}",
    cancel: "Cancel",
    confirm: "Confirm",
    backToHome: "Back to Home",
    reselect: "Reselect",
    cnacelAndLogout: "Cancel and Logout",
    thanksForOrdering: "Thanks for ordering",
    takeReceipt: "Please take your receipt",
    ordering_tip:
        " Notes:You can login to the website or app to view your order.",
    timeLeft: "No action will close in <span>{leftTime}</span> seconds",
    timeLeft1: "<span>{leftTime1}</span> Seconds to close",
    programmeDetail_lesson: "Lesson(s)",
    programmeDetail_age: "Age",
    programmeDetail_date: "Date",
    programmeDetail_location: "Location",
    programmeDetail_selectClass: "Select Class",
    programmeDetail_enrolled: "Ednrolled",
    programmeDetail_selectcoupon: "Select Coupon",
    programmeDetail_selectcoupon_tips: "you have {couponnum} available coupons",
    programmeDetail_more: "MORE",
    programmeDetail_title: "Booking Details",
    programmeDetail_id: "Booking Id",
    programme: "Programme",
    programmeSport: "Sports Programme",
    sport: "Sports",
    Sport: "Sport",
    sports: "All Sport",
    month: "Month",
    coach: "Coach",
    programmeDetail_coach: "Coach(es)",
    filter: "Filter",
    more_filter: "All Filter",
    searchPlaceholder: "Please enter sports、coach...",
    search: "Search",
    pickSport: "Select Sport",
    selectSports: "Select Sports",
    selectMonth: "Select start month",
    viewAll: 'VIEW ALL',
    enroll: 'Enrol Now',
    viewMore: 'View more',
    enjoyInKaiTak: 'ENJOY IN KAI TAK',
    checkIn: 'Check-in',
    selectOneSport: 'Select a sport',
    programmeDetail_time: "Time",
    programmeDetail_price: 'Price',
    bookingDetail_discount_txt: "Discount",
    contact_us: "Contact us:",
    menu: "menu",
    minutes: 'minutes',
    total: 'Total',
    loginTip: "Sign In / Register",
    login: 'Login',
    back: 'BACK',
    Back: 'Back',
    realNameTop: 'Real Name',
    realNameTip: 'Balloting must be in real name. The real-name information is only used as the authentication data of the event, and Kai Tak will not be used for other purposes.',
    password: "Password",
    hkId: "HKID",
    emailPhone: "Email / Phone No.",
    registerByHKID: "Register by HKID",
    registerByEmail: "Register by Email / Phone",
    scanHkId: "Scan your HKID card",
    scanHkId1: "Scan your HKID",
    or: 'OR',
    scanTip: "Place your HKID card in the scanning area as shown below",
    registerByIam: "Register by iAM Smart",
    loginByIam: "Log in with iAM Smart",
    byIam: "by iAM Smart",
    noAccount: "Don’t have a account?",
    register: "Sign up now",
    confirmInfo: "Confirm Your Information",
    hk_id: "ID",
    birthday: "Birthday",
    phone: "Phone",
    name: 'Name',
    registerTip: '*The contact will be used as your login account.',
    enterId: "Enter Your Email/Phone",
    enterPsd: "Enter Your Password",
    confirmPsd: "Confirm your password",
    errorAccount: "Incorrect email or phone number",
    errorAccountEmail: "Please enter a valid email address",
    errorAccountPhone: "Please enter a valid phone number",
    errorPwd: "Your password must have: * 8 or more characters;  * Upper & lowercase letters;  * 3 special characters.",
    differPsd: "Confirmatoin password does not match the new password",
    duplicateEmailOrPhone: "Email or phone number already exists",
    hkidHasUsed: "The HKID has been registered",
    loginNow: "The HKID has been registered and is currently logging in",
    viewDetail: "Login website&app to view details",
    scanQrCode: "Scan the QRcode",
    stepBelow: "Please follow the steps below:",
    openIam: "1.Open the iamSmart application in your phone;",
    clickScanBtn: "2.Click the scan button;",
    scanPage: "3.scan the QRcode on the page;",
    downloadIam1: "Without iamSmart please Download",
    downloadIam2: "Have no iamsmart application, Scan the QRcode right to download!",
    idPlaceholder: "Enter Your Email/Phone",
    checkInTip: "Scan your HKID or QRcode",
    checkInTip2: "Put App's QRcode or HKID in the scanning area",
    checkInSucc: "Check in successed!",
    checkInFail: "Check in failed",
    timePending: "PENDING CHECK-IN",
    scanAgain: "Failed to read ID number,please try again.",
    failReason: "Sorry, Your reservation information was not found.",
    printReceipt: "Print Receipt",
    amount: "Amount ",
    notice_txt1: "Please complete the order payment within ",
    payCountdown: "Please complete the booking in <span>{num}</span>",
    tips_txt1: "No action will close in ",
    tips_txt2: " Seconds to close",
    seconds: " seconds",
    print_txt: "Printing...",
    print_fail: "Printing failed",
    print_wrongdes: "Sorry, Printing failed there is the reason.",
    print_sucess: "Printing succeeded",
    print_take_receipt: "Please take your receipt.",
    payment_wrongdes: "Sorry, payment failed there is the reason.",
    payment_change_method: "Change Payment Method",
    payment_select_method: "Select Payment method",
    payment_method_txt1: "Credit Card",
    payment_method_txt2: "Alipay / WeChat Pay",
    payment_method_txt3: "Octopus Payment",
    noActivity: "There is no activity today",
    ok: "OK",
    justOneHour: "Sorry,peak hours just one hour is available",
    close: "Close",
    programme_title: "Programme Details",
    quota: "Quota",
    venue: "Venue(s)",
    left: "left",
    leftNum: "Remaining : {num}",
    lessonInfo: "{num}",
    ageRange: "{min}-{max} Years old",
    ageAbove: "above {age} years old",
    ageBelow: "below {age} years old",
    bottomPrice: "Fee",
    selectCoach: "Select Coach",
    searchCoachName: "Search a coach name",
    searchProgramme: "Please enter sports,coach...",
    sportsType: "Sports type",
    clearAll: "Clear All",
    enroll_now: "Enrol Now",
    book_now: "Book Now",
    venue_Details: "Venue Details",
    operation: "Operation",
    hotline: "Hot Line",
    mainStadium: "Main Stadium",
    change: "Change",
    full: "Full",
    lesson: "{num} lesson",
    lessons: "{num} lessons",
    parQ_age_limit: "The items you book need to participate in the questionnaire, please go to the web or app to complete the booking",
    parQ_answer_all: 'Please answer all questions',
    parQ_tips_option1: "I confirmed that I have been examined by doctor and certified as physically fit for participating tin hes activity.",
    parQ_tips_option2: "Need opt to produce a medical certificate to prove that I am physically fit for participating in fitness activities by staff. I shall go to the counter for further checking. Cancel Confirm.",
    parQ_title70: "Physical Activity Readiness Questionnaire",
    parQ_tips_option70_1: "I am a frequent participant in physical activities and am capable of participating in this activity.Therefore，I do not need to produce any medical certificate to prove that I am able to participate in this activity, i understand that if i have any doubts about my ability, I should consult a doctor before taking part in the activity.",
    parQ_tips_option70_2: "I am not a frequent participant in physical activities.However,I have been examined by a doctor and certified as physically fit for participating in this activity,I will upload a medical certificate during the application submission period.",
    parQ_tips_option70_3: "I hereby confirm that:",
    parQ_tips_option70_4: "I have read,understand and completed this questionnaire. Any Questions I had were answered to my full satisfaction.",
    parQ_tips_decline: "Decline",
    parQ_tips_agree: "Confirm and Agree",
    virtual_queue_title: 'Virtual Queue',
    virtual_queue_tips1: 'You are in a virtual queue.',
    virtual_queue_tips2: 'There are too many people, please wait…',
    virtual_queue_tips3: 'Sorry for the wait.',
    virtual_queue_tips4: 'Demand is very high at the moment so you may have to wait a little longer than usual to confirm the booking request.',
    checkin_tips1: "The ID number is incorrect",
    checkin_tips2: "Paper ID card",
    courtPrice: "${num}/hour",
    yes: "Yes",
    no: "No",
    reg_declaration: "Registration declaration",
    reg_scan: "Please complete the follow form enter by scan HKID",
    reg_scan_tip: "Place your HKID card in the scanning area as  shown below.",
    manuallyEnter: "Manually Enter",
    register1: 'Register',
    registerAction: 'registerAction',
    emailVerification: "Email Verification",
    finishSoon: "It will be finished soon!",
    oneStep: "There is only one step to become a KAITAK member.",
    emailAddr: "Email Address",
    emailPh: "Enter Your Email Address",
    verCodeErr: "Verification code error or timeout.",
    hasSend: "The verification code has been sended to your email <span>{email}</span>, please enter",
    registerFail: "Register failed",
    regSucc: "Registration Successful",
    sendPassword: "The inital password has been sent to the email address <span>{email}</span>.",
    login_web: "For your account security, please log in to the website to change the password and supplement your personal information.",
    emailErr: "Email address error.",
    send: "send",
    sending_limited: "The sending of the same mailbox exceeds the maximum limit.",
    emailOccupied: "Your email has been occupied. You can retrieve your account through the forgotten password process.",
    serverErr: "System error, please try again later.",
    print_paper_will_run_out: "Paper will run out.",
    print_failed: "Printing failed.",
    swipe_octopus: "Swipe your Octopus card",
    retry_please: "Retry please ",
    octopus_swipe_tip_1: "Please put your octopus in the card area",
    octopus_swipe_tip_2: "No operation in ",
    octopus_swipe_tip_3: " Will be automatically cancelled",
    octopus_swipe_tip_4: "Please tap card again！",
    octopus_swipe_tip_5: "Incomplete transaction. Please retry with the same Octopus！",
    octopus_error_tip_1: "Invalid Octopus. Please use another Octopus！",
    octopus_error_tip_2: "Insufficient value on card. Transaction cancelled. Please add value first！",
    octopus_error_tip_3: "Stored value on card exceeds limit. Please use another Octopus！",
    octopus_error_tip_4: "Machine out of order. Please contact staff for assistance！",
    link_octopus: "Linking Octopus…",
    tap_octopus: "Tap your Octopus",
    confirmation_successful: "Your confirmation is successful.",
    declaration_title: "Facility booking declaration",
    login_input_username_password: "Please enter your Email/Phone or password",
    login_repeat_submit: "Please do not submit twice",
    firstName: "First Name",
    lastName: "Last Name",
    gender: "Gender",
    idCardType: "ID Card Type",
    idCardNum: "ID Card Number",
    birth: "Date of Birth",
    notEmpty: "{property} can not be empty",
    male: "Male",
    female: "Female",
    any: "Any",
    other: 'Other',
    scanFailed: "ID card number scan acquisition failed",
    noCourt: "No court found",
    searchMember: "Search a member name",
    enterVcode: "Enter Verification Code",
    noData: "No data found",
    morning: "Morning Session",
    afternoon: "Afternoon Session",
    evening: "Evening Session",
    avail: "Available",
    normal: "Normal",
    less: "Less",
    partnerUpperLimit: "You can only add {num} partner(s) at most",
    partnerLowerLimit: "You need to add at least {num} partner(s)",
    duplicatePartner: "You have added {partner}",
    deleteFail: "Delete failed",
    selectRate: "special population special rate options",
    class: "Class",
    classDetail: "Class Detail",
    imgClassTips: "pictures",
    selectSport: "Facility Sports",
    Venue: "Venue",
    Fee: "Fee",
    selectVenue: "Select Venue",
    guardian: "Guardian Contact",
    eCouponCode: "e-Coupon Code",
    this_coupon_code: "This coupon code is invalid or has expired.",
    eCouponCodePlaceholder: "Please enter e-Coupon code",
    serialNo: "Serial No.",
    "facility_apply": "Apply",
    "facility_check": "Check",
    valid: "Valid",
    contactName: "Contact Name",
    relationship: "Relationship",
    guardianPhone: "Guardian Phone",
    guardianEmail: "Guardian Email",
    add: "Add",
    delete: "Delete",
    selectRateType: "Select Rate",
    confirmBooking: "Confirm Booking",
    nameLength: "Length should be 1-32 characters.",
    contactNamePlaceholder: "Please enter Contact Name.",
    relationshipPlaceholder: "Please select Relationship.",
    guardianPhonePlaceholder: "Please enter Guardian Phone.",
    guardianEmailPlaceholder: "Please enter Guardian Email.",
    guardianEmailPlaceholderType: "Please enter correct email, limit 1-128 characters.",
    phoneLength: "Please enter 8 pure digits.",
    contactPhone: "Contact Phone",
    contactEmail: "Contact Email",
    completeOrder: "Complete the order and get",
    points: "points",
    regAgreement: "Registration Term and Conditions:",
    checkAgreementRegConfirm: "Please check the KAITAK agreement before confirm.",
    bookingRegAgreement: "Terms & Conditions",
    regAgreementTips: "Thank you for registering with KAITAK Sports Park.",
    regAgreementTips1: "I have read and accept the terms and conditions.",
    enterEmailOrPhone: "Enter your email/phone",
    enterAny: "Enter your {label}",
    enterEmail: "Enter your email",
    enterPhone: "Enter your phone",
    question: "Question",
    YES: "YES",
    NO: "NO",
    selectPaymentMethod: "Select Payment Method",
    creditCard: "Credit Card",
    fps: "FPS / Alipay / WeChatpay / Payme",
    payMethod: "Payment Method",
    bookingNotes: "Booking notes:",
    booking_Note: "Booking Information:",
    bookingNotes_1: "Each booking allows you to reserve one court. During peak hours, each court can be reserved for a maximum of 2 hours, while during non-peak hours, each court can be reserved for up to 4 hours. Once your booking is confirmed, no refunds will be provided.",
    bookingNotes_2: "Please be aware that smoking is strictly prohibited in all venues, including non-smoking areas.",
    bookingNotes_3: "To ensure a safe and enjoyable experience, kindly wear suitable sportswear and athletic shoes while using the facilities.",
    bookingNotesCon: "Please note that each booking is restricted to one venue, with a maximum court reservation of 2 hours during peak hours and 4 hours during non-peak hours. Refunds are not available once a booking is confirmed, so please make your selections carefully. Our venues are smoke-free zones, and smoking is strictly prohibited. Additionally, we kindly ask that you wear suitable sportswear and sneakers for your sporting activities. Thank you for your cooperation and understanding.",
    selectPaymentMethodTips: "Please select payment method",
    receiptNo: "Receipt no.",
    bookingNumber: "Booking Number",
    octopus: "Octopus",
    logout: "Logout",
    notes: "Notes",
    checkInTimeStartTip: "The check in time start at",
    checkInTimeEndTip: "by the QRcode.You can login to the website or App to view your booking details.",
    paymenCodeTip: 'Please scan the QR code to initiate the payment process. After the payment is successfully  processed, click the ‘Confirm’ button to complete your reservation.',
    payment: "Payment",
    loginNote: "All personal data collected will be used only for the purpose of registration. Unless permitted or required by law, Kai Tak Sports Park will not disclose your personal data to any third parties without your prior consent.",
    bookingNotes1: "Your personal in information is only used for registration as a Kai Tak Sport Park member.Kai Tak Sport Park will not use for other purposes. One account can be registered forone identity.",
    bookingNotes2: "Non-member login will automatically register as a member.",
    guardian_name: "Guardian Name",
    ballot_history_notes: "Here are all kinds of explanations about the voting results，Each booking is limited to 1 court, and each court is limited to 2 hours when peak hour,non-peak is limited 4 hours. Once booked, no refund will be given, please be careful! 2. Non-smoking venue, and smoking is strictly prohibited in the venue.",
    addEquipment: "Add Equipment",
    pointsDeduction: "*Every {point} points for HK${price} discount",
    playerName: "Player Name",
    phoneNumber: "Phone Number",
    phoneNo: 'Phone No.',
    email: "Email",
    memberId: "Member ID",
    addPlayer: "Add Player",
    programmeDetailSelect: "Select",
    emergencyContact: "Emergency Contact",
    ktspPoints: "KTSP Points",
    total1: "Total",
    results: "Results",
    confirmDelTips: "Do you want to delete this item?",
    multipleSport: "This sport requires multiple participants, please go to the web to book.",
    noVenue: "No venues or courts found",
    peakHourLimit: 'Over peak hour booking limit, please click the "OK" button to proceed with your booking.',
    notPeakHourLimit: 'Over off-peak hour booking limit, please click the "OK” button to proceed with your booking.',
    HourLimit: 'Over booking limit, please click the "OK” button to proceed with your booking.',
    onlyOneCourtPerPeriod: "Only one court can be chosen for each time period",
    onlyOneCourtForBallot: "Each person can only join balloting once per session.",
    oneCourtLimit: "Please select a session from current page to book it.",
    withoutCheckIn: "Due to multiple successful bookings without checking in, you are currently unable to book facility.",
    courtNeedSameDay: "The selected courts need to be on the same day",
    uncheckParQ: "Please check PAR-Q information!",
    noMoreCoach: "No more coaches",
    howtoBallot: "How Balloting Works?",
    ballotingHistory: "Balloting History",
    ballotingDes1: "Enroll in the balloting process by verifying your real name using your HKID or iAM Smart. Select your preferred date and time, and submit your entry. Once the balloting results are in, winners will receive an email notification, allowing them to proceed with payment and confirm their booking.",
    ballotingDes2: " or ",
    ballotingDes3: ". After choosing the course you like,click the ",
    ballotingDes4: " button",
    enroll_ballot: "Enroll Ballot",
    ballotingTips: "**Balloting must be done with real names. Please scan your HKID or log in using iAM Smart for verification.",
    programmeBallot: "Programme Balloting",
    ballotNotice: "Please check the information.VBS will only save the following displayed information as the registration data.",
    guardian1: 'guardian1',
    guardian2: 'guardian2',
    partnerLimitWarnTxt: "The number of participants cannot be greater than {max}.",
    realnameBookTips: "The current user has not yet completed name verification and cannot continue booking. Please verify the name first.",
    leastOneGuar: "You have to have at least one guardian",
    noConfigAmount: "Payment amount information is not configured for this course",
    facilityBallot: "Facility Balloting",
    ballotingDetails: 'Balloting Details',
    announceDate: 'Announcement Date',
    submitSccessful: 'Submitted successfully!',
    language: 'Language',
    languages: 'Language(s)',
    importantInfo: 'Important Information',
    howToPlay: 'How to play',
    selectTime: 'Select timeslot',
    idNo: 'ID No.',
    ballotingTip: 'The results of the balloting will be announced on ',
    ballotingTip1: '. To view your balloting details and check the results,  please log in to our website or App.',
    balloting_download: 'Download the app to explore more sports and learn about the latest information.',
    downloadApp1: 'Download the app to explore',
    downloadApp2: 'more and get the latest information',
    vote: '*Balloting must be in real name. Please scan your HKID or login by iAM Smart.',
    confirmBalltoing: 'Please check the information.VBS will only save the following displayed.',
    ballotingResult: 'Balloting Result',
    ballotingDate: 'Balloting date',
    bookingFailed: 'Booking failed',
    bookingFailedReason: 'Sorry, the reason for the booking failure is that the price has not been configured.',
    viewResult: "View Result",
    confirmBallotTitle: "Confirm your Balloting",
    general_label_is_requeird: "{label} is required",
    realNameBookingTips: "This Venue requires HKID authentication. Please complete authentication process before booking.",
    realNameBookingTipsSports: "Selected sports requires HKID authentication,  Please complete authentication process before booking.",
    queueTimeout: "Queue timeout, please try again later",
    Repay: "Repay",
    declarParq: "PAR-Q",
    parqYesTips: "The Par-Q information did not meet the reservation requirements and the reservation could not be continued.",
    addOnsFee: "Equipments",
    requiredTips: "Required Information",
    noMoreData: 'No more data',
    time_am: 'AM',
    time_pm: 'PM',
    noAgeLimit: 'No age limit',
    thankPay: 'Thank you for the',
    checckPay: 'Please check your email for the',
    bookDetail: 'details of your booking.',
    exit: 'Exit',
    continue: 'Continue',
    checkEmailTips: 'Please check your email for the details of your booking. ',
    logout_upcase: 'LOGOUT',
    welcome_username: 'Welcome, ',
    hi_username: "Hi, {username}.",
    reminder_time: "You still have {remderTime} Minutes to complete the booking, please take your time.",
    octopusErrorTips1: 'Machine out of order. Please contact staff for assistance.',
    octopusErrorTips2: 'Invalid Octopus. Please use another Octopus.',
    octopusErrorTips3: 'Insufficient value on card. Transaction cancelled. Please add value first.',
    octopusErrorTips4: 'Stored value on card exceeds limit. Please use another Octopus.',
    octopusErrorTips5: 'Please tap card again.',
    octopusErrorTips6: 'Incorrect card, retry please(Octopus no. {num}).',
    octopusErrorTips7: 'Read card error,retry please.',
    payTimeOut: "We couldn't process your payment. Please try again latter.",
    errorPwdOrEmail: "Sorry, your Email or password was incorrect. Please try again.",
    errorGuardiansTips: "Please fill in Guardian's information to complete the booking.",
    errorEmergencyContactTips: "Please fill in emergency contact's information to complete the booking.",
    posPaymentCode: {
        '00': 'Transaction approved.',
        '01': 'Call card center to get approval code.',
        '02': 'Call card center to get approval code.',
        '03': 'INVALID MERCHANT',
        '04': 'PICK UP CARD',
        '05': 'DO NOT HONOUR',
        "08": "APPROVED WITH ID",
        "12": "BAD PROCESS CODE",
        "13": "INVALID AMOUNT",
        "14": "INVALID ACCOUNT",
        "19": "RE-ENTER TRANS.",
        "25": "INVALID TERMINAL",
        "30": "EDIT ERROR",
        "31": "UNKNOWN CARD",
        "41": "PLEASE CALL - LC",
        "43": "PLEASE CALL - CC",
        '51': 'Transaction rejected.',
        "54": "CARD EXPIRED",
        "55": "INCORRECT PIN",
        "58": "INVALID SERVICE",
        "60": "CONTACT ACQUIRER",
        "76": "BAD PRODUCT CODE",
        "77": "RECONCILE ERROR",
        "78": "TRACE NOT FOUND",
        "80": "BAD BATCH NUMBER",
        "85": "BATCH NOT FOUND",
        "88": "APPRV, CALL AMEX",
        "89": "BAD TERMINAL ID",
        "91": "SYSTEM NOT AVAIL",
        "94": "DUPLICATE TRACE",
        "95": "BATCH TRANSFER",
        "96": "INVALID MESSAGE",
        "AP": "APPV CODE",
        "BE": "BASE ERROR",
        "BL": "LOW BATTERY",
        "BT": "RECORD ERROR",
        "CB": "PLS SETTLE BATCH",
        "CN": "TRAN. CANCELLED",
        "DE": "DATA ERROR",
        "EC": "ECR COMM. ERROR",
        "FF": "DECLINED (FF) DBS only.",
        "HO": "PLS TRY AGAIN-NC DCC only.",
        "IK": "KEY PARITY ERROR",
        "IM": "CALL HELP - IM",
        "IP": "CALL HELP – IP Processing code mismatch",
        "IR": "CALL HELP – IR Message ID mismatch",
        "IS": "CALL HELP – IS STAN mismatch",
        'IT': "CALL HELP – IT TID mismatch",
        "LC": "PLS TRY AGAIN-LC Line dropped",
        "LN": "NO DIAL TONE",
        "LO": "LINE OCCUPIED",
        "MA": "UNSUPPORTED CARD",
        "NB": "NO BATCH TOTAL",
        "NC": "PLS TRY AGAIN-NC Fail to connect to host",
        "NE": "NOT ALLOWED",
        "NG": "NEG. BATCH TOTAL",
        "NJ": "NO ADJUSTMENT",
        "NS": "SALE NOT ALLOWED",
        "NT": "NO TRANS. RECORD",
        "OL": "ADJUST OVR LIMIT",
        "OT": "INPUT TIMEOUT",
        "PF": "INVALID PASSWORD",
        "RE": "CARD READ ERROR",
        "SB": "CARD BLOCKED EMV Card Blocked",
        "SC": "PLS SWIPE CARD Fallback",
        "SE": "SYSTEM ERROR",
        "TA": "TRANS. ACCEPTED",
        "TO": "PLS TRY AGAIN-TO",
        "UC": "UNSUPPORTED CARD Card range is not supported",
        "UL": "UNDER MIN AMOUNT",
        "VD": "TRANS. VOIDED",
        "VS": "VERIFY SIGNATURE",

        // '41': 'Lost card.',
        // '43': 'Stolen card. ',
        // '77': 'This happens in Hong Kong bank host that no batch upload and settlement slip will be printed. ECR should treat this same as batch up load case',
        // '78': 'Specified record cannot be found',
        // 'CB': 'Please do settlement as record memory is full',
        // 'CN': 'Canceled by operator.',
        // 'LC': 'Communication failed',
        // 'NC': 'Connection failed.',
        // 'NJ': 'Adjustment is not allowed',
        // 'NT': 'No transaction in batch',
        // 'OT': 'S9000 input timed out.',
        // "SE": "System Exception",
        // 'TO': 'Response time out.',
        // 'UC': 'Unsupported card type',
        // 'VD': 'Transaction voided',
        'BB': 'Terminal is busy or unavailable.',
        'RF': 'Password fail',
        'NA': 'Authorization is not allows',
        'NM': 'Manual key in is not allowed',
        'NO': 'Offline sale is not allowed',
        'NR': 'Refund sale is not allowed',
        'NV': 'Void sale is not allowed',
        'AN': 'Host or acquirer cannot be found',
        'OR': 'Design to do an over-ride transaction or not',
        'NP': 'Indicate printer no paper and request cancel',
        'XX': 'Handset not put on the base',
        'ME': 'MAC Error',
        'EP': 'Terminal is at reversal pending mode',
        "PYE": "Payment type error",
        "OCF": "Exception in creating payment connection",
    },
    errorIamSmartSq: 'No message received, please try again.',
    selectPayMethod: 'Please select payment method before checkout.',
    proceedCheckout: 'Proceed to Checkout',
    unCompleteEmergency: 'Please complete Emergency contact before proceed to checkout.',
    unCompletecheckParQ: "Please check PAR-Q information before proceed to checkout.",
    unCompleteGuardiansTips: "Please complete guardian's information before proceed to checkout.",
    unCompletecheckParQConfirm: "Please check PAR-Q information before confirm.",
    checkAgreementConfirm: 'Please check the KAITAK booking agreement before confirm.',
    existYesParQ: "Participants with 'Yes' responses to PAR-Q are strongly advised to seek medical guidance before proceeding. Are you sure you want to Continue?",
    booking_summary: "Booking Summary",
    "booking_slots": "Booking Slots",
    no_more_data: 'No more data',
    loading_data: 'loading',
    check_out: "CHECK OUT",
    Check_Out: "Check Out",
    facility_menu: "Sports & Facility Bookings",
    programmeSport_menu: "Community Programmes",
    checkIn_menu: "Check-in",
    points_earned: "Points Earned",
    "Select_Time": "Select Time",
    submit: "Submit",
    back_txt: "Back",
    bookingChangeTime: {
        threeTime: 'Each booking is only allowed to reserve time slots on the same day, and a maximum of three time slots can be booked.',
        onePeak: '(Only one peak hour can be included.)',
        clickTips: 'Please click the "Confirm" button to proceed with your booking.'
    },
    available_tomorrow: "Available Tomorrow",
    facility_choose_session: "Select Time",
    notes_enrolment: {
        title: "Notes on Enrolment",
        desc1: "1.Patrons can submit their balloting applications for Kai Tak Sports Park (KTSP) booking system.",
        desc2: "2.Each patron can only submit application for one session of one facitlity on each balloting date.",
        desc3: "3.Patron who duplicate their enrolments or submit incomplete applications will be disqualified.",
        desc4: "4.Successful applicants are obligated to complete the payment process within the designated timeframe through the KTSP website or mobile application. Failure to submit payment by the specified deadline will result in the voluntary forfeiture of their application."
    },
    programme_choose_class_tip: 'Please Choose a Class',
    facility_full: "Full",
    facility_available: "Available",
    facility_shortage: "Limited",
    opstips: 'Transaction in progress, please wait a moment...',
    opstips2: 'Payment unsuccessful, reference code 100022,<br>please try again or cancel this payment request',
    opsdownloadTips: 'Data is syncing.<br> Please do not perform other operations.',
    ballotingRegAgreement: "KAITAK Balloting Agreement",
    facility_label_equipment_fee: "Equipment Rental Fee",
    "Coupon_Type": "Coupon Type",
    "Coupon_Expire_Date": "Expiry Date",
    "programme.coupon.code": "Coupon Code",
    "programme.coupon.value": "Coupon Value",
    "coupon_tips": "* Each booking can only use one coupon.",
    terms_and_conditions: "Terms & Conditions",
    readParQ: "I have read and accepted the PAR-Q.",
    facility_terms_and_conditions: "I have read and accept the terms and conditions.",
    selected_slots: "Selected Slots",
    "payment.AvailableKTSPpoints": "Available KTSP points",
    "Use_All_Points": "Use All Points",
    "facility_available_ktsp_points": "Available KTSP Points",
    "myProfile_my_player_list": "My Player List",
    pay_success_tips2: "more sports and learn about the latest information",
    ktsp_app: "KTSP APP",
    iamsmart_timeout: "Response timeout, please try again.",
    octopusNo: 'Octopus No.',
    amountDeducted: 'Amount deducted',
    remainingValue: 'Remaining Value',
    lastChargeTip: 'Last add value by {type} on {time}',
    chargeTypeMap: {
        Cash: 'Cash',
        Online: 'Online',
        Refund: 'Refund',
        AAVS: 'AAVS',
        Others: 'Others'
    },
    payMethodMap: {
        OCTOPUS: 'OCTOPUS',
        VISA: 'CREDIT CARD',
        UNION_PAY: 'CREDIT CARD',
        ALIPAY: 'ALIPAY HK',
        WECHAT_PAY: 'WECHAT PAY'
    },
    paymentRefNumber: 'Payment Ref Number',
    next: 'Next',
    date_time: 'Date/Time',
    shop_no: 'Shop no.',
    device_no: 'Device no.',
    "programme_form_activity_Id": "Programme ID",
    "programme_form_No_of_Class": "No. of Class",
    "programme_form_Pass_Availability": "Pass Availability",
    "programme_group": "Group",
    "programme_match_group": "Match Group",
    "playerList_select_tips": "*You must need to pick at least {label} attendee",
    "playerList_attendee_list": "Attendee list",
    "Attendee": "Attendee",
    "playerList_child_list": "Child List",
    "playerList_friend_list": "Friend List",
    "playerList_type_BOOKER": "Booker",
    "playerList_type_CHILD": "Child",
    "playerList_type_FRIEND": "Friend",
    "playerList_overage": "Overage",
    "attendee_subTitle_fixed": `<p>You can manage the attendees here.</p>`,
    "attendee_subTitle": `<p>You can manage the attendees here.</p>
    <p>To avail the student discount, please change the Select Rate.</p>`,
    "attendee_add_you": "You",
    "ivtLimit": {
        Incompatible: "Incompatible",
        Incompatible_tip: `At the beginning of the course, the age or gender of the participants did not meet the requirements.Please select another course.`
    },
    "equip_title": 'Rent Equipment(s)',
    "payment.nodata": "No Data",
    "payment.noImg": "No Pictures",
    "Emergency_0": "Primary ",
    "Emergency_1": "Secondary ",
    "Emergency_Contact": "Emergency Contact",
    "Guardian_Contact_tips":"Ensure safety by adding primary and secondary Guardian contacts. Provide crucial information for unexpected situations.",
    "Emergency_Contact_tips":"Ensure safety by adding primary and secondary emergency contacts. Provide crucial information for unexpected situations.",
    "Guardian_Contact": "Guardian Contact",
    "general_email_tips": "Please enter the correct email address.",
    "facility_relationship": "Relationship",
    "facility_relations_number": "Phone No.",
    "facility_contact_email": "Email",
    "general_label_name": "Name",
    "general_label_Type": "Type",
    cch_venue: "Venue",
    "facility_ktsp_points": "KTSP Points Burn",
    "facility_ktsp_points_discount": "KTSP Point Discount",
    "facility_coupon_code": "Coupon code",
    "facility_coupon": "Coupon",
    programme_age_limit: "Age Limit",
    programme_form_Date_of_Enroll: "Enrolment Period",
    programme_form_Venue: "Venue",
    programme_form_Coach: "Coach",
    programme_form_Language: "Language",
    programme_form_Price: "Fee",
    programme_form_Quota: "Quota",
    programme_form_Date: "Date",
    programme_form_Class_detail: "Class Detail",
    programme_class_id: "ID",
    facility_time: "Time",
    session_8_day_tip: "* Day {cchfcfs} is for view only",
    "add_Guardian": "Add Guardian Contact",
    "add_Emergency": "Add Emergency Contact",
    general_button_save: 'Save',
    general_button_cancel: 'Cancel',
    facility_button_confirm: 'Confirm',
    facility_button_delete: 'Delete', facility_date: "Date",
    booking_slots_tips_typec: "You need to book {numOfClass} sessions",
    facility_button_add: "Add",
    general_label_select_day_time: "CHOOSE DATE & TIME",
    morning_session: "Morning Session",
    afternoon_session: "Afternoon Session",
    evening_session: "Evening Session",
    booking_slots_tips_repetitive: "Duplicate session were found",
    general_label_select: "Select Date",
    booking_slots_tips_choose: "Please choose sessions",
    notEnoughtActivity: "Not enough activity quota left for enrolement",
    "facility_session": "Session",
    "choose_available_session": "Select Session",
    programme_choose_group: "Choose Group",
    programme_choose_match_group: "Choose Match Group",
    programme_choose_a_class: "Select a Class",
    application_received: 'application received',
    Guide_winners: "Guide for the Balloting winners",
    balloting_Id: "Balloting ID",
    facility_on_blacklist: "Sorry, you are on blacklist",
    "wr_exceed_waiting_capacity": "The waiting list limit has been exceeded.",
    "wr_no_permits_request": "No permitted requests, please log out of the account and log in again.",
    facility_ballot: "Balloting",

    "facility_label_payment_method": "Payment Method",
    "facility_label_octopus": "Octopus Payment",
    "facility_label_payment_time": "Payment Time",
    "facility_label_Reference_Number": "Reference Number",
    "facility_points_used": "Points Used",
    "facility_payment_summary": "Payment Summary",
    "attendee_add_ons": "Add-On(s)",
    "attendee_add_on_description": "If necessary, you Add-On(s) List the option to rent different types of Add-On for your needs.",
    general_label_person: ' attendee(s)',
    "booking_success": "Booking Successful",
    "booking_success_checkin": "The check-in time starts at {label} on {date}",
    "booking_you_can_also_go": "A confirmation email will be sent to your personal account. You can also go to My Record to view details.",
    "programme_checkout_message": "There is an unpaid order for the {name} program. Please complete the payment first.",
    "no_write_emergency": "Please fill in the information of the emergency contact first.",
    "no_write_guardian": "Please fill in the information of the guardian first.",
    "noSelect_attendee_tip":"Please select attendee.",
    "checkAgreement": "Please check the KAITAK booking agreement before checking out.",
    "checkParQAgreement": "Please check the PAR-Q booking agreement before checkout.",
    "checkAgreement_ballot": "Please check the KAITAK booking agreement before submitting.",
    "checkParQAgreement_ballot": "Please check the PAR-Q booking agreement before submitting.",
    "facility_peak_hour": "Peak Hour",
    "max_attendees":"Select a maximum of {num} attendees.",
    "min_attendees":"Select at least {num} attendees.",
    "register_success_tip": "Thank you for creating an account on the KTSP booking system. Your registration is now complete and you can start booking venue and programme.",
    "select_rate_tip":"Choosing a rate will directly affect the price.",
    cch_select_venue: "Select Venue",
    "Selected_Slot":"Selected Slot(s)",
    greeting1GM: 'Good Morning',
    greeting1GA: 'Good Afternoon',
    greeting1GE: 'Good Evening',
    programe_category: 'Category',
    programe_sport_type: "Sport Type",
    general_label_reset: 'Reset',
    choose_dates: 'Choose Dates',
    flexible_dates: 'Flexible Dates',
    programe_today: 'Today',
    programe_tomorrow: 'Tomorrow',
    programe_thisWeek: 'This Week',
    programe_nextWeek: 'Next Week',
    programe_nextMonth: 'Next Month',
    programe_allTime: 'Any Time',
    "failed_login": "Failed to login.",
    octopus_device_id: 'Octopus Device Number',
    octopusAddMount: 'Octopus last recharge amount',
    octopusAddTime: 'Octopus last recharge time',
    "all":"All",
    octopus_add_value_info: 'Last add value by {type} on {date}',
    cash: 'Cash',
    online: 'Online',
    aavs: 'AAVS',
    general_validation_error_invalid_value: 'Please enter a valid {field}',
    "programe_age_all": 'All',
    "programme_age_children": "Children (Ages 3-11)",
    "programme_age_youths": "Youths (Ages 12-17)",
    "programme_age_adults": "Adults (Ages 18-59)",
    "programme_age_seniors": "Seniors (Age 60+)",
    norealname_user_tips: "The current account has not yet completed name verification and cannot continue booking. Please verify the name.",
    retrieval_ref_num: "Retrieval Reference Number",
    terminal_id: "Terminal ID",
    merchant_id: "Merchant ID",
    approval_code: "Approval Code",
    entry_mode: "Entry Mode",
    card_no: "Card No.",
    expiration_date: "Expiration Date",
    emv_applic_id: "EMV Application ID",
    emv_tranc_crypto: "EMV Transaction Cryptogram",
    retry_btn: 'Retry',
    retry_ops: '<br>Retry please (Octopus no. {opsNo})',
};
